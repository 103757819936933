<template>
 <div class="bg-white shadow-md rounded-lg pb-4">
  <h2 class="text-center font-bold text-sm -mb-4">Appels perdus</h2>
  <div class="lg:flex justify-center items-center text-center">
   <div id="chart">
    <apexchart
     type="radialBar"
     height="350"
     :options="chartOptions"
     :series="series"></apexchart>
   </div>
   <div class="items-center lg:text-left sm:text-center">
    <div class="text-xs">
     <span>Appels perdus</span>
    </div>
    <div class="flex justify-center text-lg font-bold">
     <span class="text-red-600">{{ lost }}</span
     >/<span class="text-blue-600">{{ total }}</span>
    </div>
   </div>
  </div>
  <div
   class="flex justify-center items-center px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 -mb-4 h-1/4">
   <div class="">
    <span class="font-bold"> {{ ((lost / total) * 100).toFixed(0) }}% </span>
    des appels ont été redirigés vers une messagerie ou ont été abandonnés car
    personne ne répondait.
   </div>
  </div>
 </div>
</template>

<script>
export default {
 props: ["total", "lost"],
 data() {
  return {
   series: [((parseInt(this.lost) / parseInt(this.total)) * 100).toFixed(0)],
   chartOptions: {
    chart: {
     fontFamily: "Nunito, sans-serif",
     type: "radialBar",
     offsetY: 0,
    },
    plotOptions: {
     radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
       background: "#e7e7e7",
       strokeWidth: "97%",
       margin: 5, // margin is in pixels
       dropShadow: {
        enabled: true,
        top: 2,
        left: 0,
        color: "#999",
        opacity: 1,
        blur: 2,
       },
      },
      dataLabels: {
       value: {
        offsetY: -2,
        fontSize: "20px",
        fontFamily: "Nunito, sans-serif",
        color: "#DC143C",
        formatter: function (val) {
         return val + "%";
        },
       },
      },
     },
    },
    fill: {
     type: "solid",
     colors: ["#DC143C"],
     gradient: {
      shade: "dark",
      shadeIntensity: 0.9,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 65, 91],
      gradientToColors: ["#DC143C"],
     },
    },
    stroke: {
     dashArray: 4,
    },
    labels: [""],
    responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 350,
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              value: {
                fontSize: "16px",
              },
            },
          },
        },
      },
    },
    {
      breakpoint: 768,
      options: {
        chart: {
          width: 400,
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              value: {
                fontSize: "18px",
              },
            },
          },
        },
      },
    },
  ],
   },
  };
 },
 methods: {},
 mounted() {
  this.$emit("setLoading", false);
 },
 updated() {
  this.$emit("setLoading", false);
 },
};
</script>

<style></style>
