<template>
 <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6 ">
  <div class="flex justify-between border-gray-200 border-b dark:border-gray-700 pb-3">
   <dl>
    <dt class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1">Calls origin top 10</dt>
    <!-- <dd class="leading-none text-3xl font-bold text-gray-900 dark:text-white">{{ ((totalAnswered / (totalAnswered + totalUnanswered)) * 100).toFixed(0) }}%</dd> -->
   </dl>
   <!-- <div>
    <span class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
     <svg class="w-2.5 h-2.5 mr-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4" />
     </svg>
     Since last month 23.5%
    </span>
   </div> -->
  </div>

  <!-- <div class="grid grid-cols-2 py-3">
   <dl>
    <dt class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1">Answered</dt>
    <dd class="leading-none text-xl font-bold text-green-500 dark:text-green-400">{{ totalAnswered }}</dd>
   </dl>
   <dl>
    <dt class="text-base font-normal text-gray-500 dark:text-gray-400 pb-1">Unanswered</dt>
    <dd class="leading-none text-xl font-bold text-red-600 dark:text-red-500">{{ totalUnanswered }}</dd>
   </dl>
  </div> -->

  <div class="" v-if="seriesTotal.length > 0 && labelTotal.length > 0">
   <DonutChartNarrow :dataSeries="seriesTotal" :chartLabel="labelTotal" :title="title" :subtitle="subtitle" :colors="this.colors" />
  </div>
  <div class="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
   <div class="flex justify-between items-center pt-5">
    <!-- Button -->
    <Menu as="div" class="relative inline-block text-left">
     <div>
      <MenuButton class="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white capitalize">
       {{ quickPeriod }}
       <svg class="w-2.5 m-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
       </svg>
      </MenuButton>
     </div>

     <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
     >
      <MenuItems class="absolute left-0 z-10 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
       <div class="py-2 text-sm text-gray-700 dark:text-gray-200">
        <MenuItem v-for="option in periodOptions" :key="option.title">
         <a @click="(periodValue = option.value), $emit('quickPeriod', option.title)" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white capitalize cursor-pointer">{{ $t(option.title) }}</a>
        </MenuItem>
       </div>
      </MenuItems>
     </transition>
    </Menu>
    <a href="/expert-statistics-origin-detail/queue" class="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
     Origin Top 10 Report
     <svg class="w-2.5 h-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
     </svg>
    </a>
   </div>
  </div>
 </div>
</template>

<script>
import DonutChartNarrow from '../../DonutChartNarrow.vue'
import DonutChart from "../../DonutChart.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

const periodOptions = [
 //  { title: "today", current: false, value: 0 },
 //  { title: "yesterday", current: true, value: 1 },
 { title: "last 7 days", current: false, value: 7 },
 { title: "last 30 days", current: false, value: 30 },
 //  { title: "last 90 days", current: false },
];

export default {
 props: ["data", "title", "subtitle", "concatenateData", "fullData", "quickPeriod"],
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  DonutChart, DonutChartNarrow
 },
 data() {
  return {
   periodValue: null,
   periodOptions,
   seriesTotal: [],
   labelTotal: [],
   colors: [],
   pbx3cxData: [],
   callData: [],
   callDataHistory: [],
   callDataParticipants: [],
   callDataSegments: [],
   hostName: "demo-encom.3cx.fr",
   limit: 1,
   pbxExtensions: [],
   pbxQueues: [],
   monthlyDataExtensions: [],
   monthlyDataExtensionsSeries: [],
   monthlyDataExtensionsLabels: [],
   monthlyDataQueuesSeries: [],
   monthlyDataQueuesLabels: [],
  };
 },
 methods: {
  openDetail() {
   let queue = [];
   let splitElement = this.fullData.name.split(" - ");
   queue.push(splitElement[0]);
   console.log("file: DonutsDashboard.vue ~ line 56 ~ openDetail ~ queue", queue);
   this.$emit("openDetail", queue);
  },
  getAndSortData() {
   this.colors = [];
   if (this.data) {
    this.labelTotal = Object.keys(this.data).sort();
    let keys = Object.keys(this.data).sort();
    keys.forEach((element, index) => {
     this.seriesTotal.push(parseInt(this.data[element]["dataObjSerie"]));
     if (this.data[element]["dataObjColor"]) {
      this.colors.push(this.data[element]["dataObjColor"]);
     }
    });
   }
  },
 },
 mounted() {
  this.getAndSortData();
  this.$emit("loading", false);
 },
 computed: {
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
 watch: {
  periodValue: function (val) {
   this.$emit("selectPeriod", val);
  },
 },
};
</script>
