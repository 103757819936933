<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="_cancel">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <DialogOverlay
       class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <!-- This element is to trick the browser into centering the modal contents. -->
     <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
      <form
       @submit.prevent="_confirm"
       class="relative sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all">
       <div class="sm:flex sm:items-start">
        <div
         class="absolute mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 sm:mx-0 sm:h-10 sm:w-10">
         <font-awesome-icon
          :icon="['far', 'bell']"
          class="h-5 w-5 text-gray-100"
          aria-hidden="true" />
        </div>
        <div class="pl-8 text-center sm:mt-0 sm:ml-4 sm:text-left">
         <DialogTitle
          as="h3"
          class="pb-2 text-lg leading-6 font-medium text-gray-900 flex">
          <p class="self-center">Créer une alerte</p>
          <input
           type="text"
           name="report"
           v-model="form.name"
           required=""
           class="ml-2 sm:text-sm border-gray-300 rounded-md h-8"
           :placeholder="$t(`Nom de l'alerte`)" />
         </DialogTitle>

         <div class="bg-white rounded-lg py-4">
          <div class="space-y-2 items-center">
           <!-- <div>
            <label
             for="host_name"
             class="block text-sm font-medium text-gray-700"
             >Host Name</label
            >
            <input
             v-model="form.host_name"
             required=""
             id="host_name"
             type="text"
             class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
           </div> -->

           <!-- <div>
            <label for="name" class="block text-sm font-medium text-gray-700"
             >Name</label
            >
            <input
             
             id="name"
             type="text"
             class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
           </div> -->
           <div class="flex gap-2 items-center">
            <span> Je souhaite recevoir une alerte lorsque </span>
            <!-- <label
              for="stats_type"
              class="block text-sm font-medium text-gray-700"
              >Type de statistiques</label
             > -->
            <select
             v-model="form.stats_type"
             required=""
             id="stats_type"
             class="p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
             <option
              v-for="option in statsTypes"
              :key="option.id"
              :value="option.type">
              {{ option.name }}
             </option>
            </select>
            <span> sont : </span>
           </div>

           <div class="flex gap-2 items-center">
            <!-- <label
             for="stats_measurement_type"
             class="block text-sm font-medium text-gray-700"
             >Type de mesure</label
            > -->
            <select
             v-model="form.stats_measurement_limit"
             required=""
             id="stats_measurement_limit"
             class="p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
             <option
              v-for="option in statsMeasurementLimits"
              :key="option.id"
              :value="option.type">
              {{ option.name }}
             </option>
            </select>

            <input
             v-model="form.stats_measurement_value"
             required=""
             id="stats_measurement_value"
             type="number"
             min="0"
             class="p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
            <!-- <select
             v-model="form.stats_measurement_type"
             required=""
             id="stats_measurement_type"
             class="p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500">
             <option
              v-for="option in statsMeasurementTypes"
              :key="option.id"
              :value="option.type">
              {{ option.name }}
             </option>
            </select> -->
            <input
             v-show="form.stats_type === 'answeredIncomming'"
             disabled=""
             value="%"
             id="stats_measurement_value"
             type="text"
             min="0"
             class="p-1 w-10 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
            <input
             v-show="form.stats_type === 'totalDurationIncomming'"
             disabled=""
             value="secondes"
             id="stats_measurement_value"
             type="text"
             min="0"
             class="p-1 w-20 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
           </div>

           <div class="pt-2 flex gap-2 items-center">
            <div>
             <label for="schedule" class="block"
              >Fréquence de l'alerte :
             </label>
             <select
              id="schedule"
              name="schedule"
              class="p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              v-model="this.form.repeat_pattern"
              required="">
              <option value="day">{{ $t("Tous les jours") }}</option>
              <option value="week">{{ $t("Toutes les semaines") }}</option>
              <option value="month">{{ $t("Tous les mois") }}</option>
             </select>
            </div>
            <span class="mt-6"> à compter du </span>
            <input
             v-model="form.start_at"
             type="date"
             class="mt-6 p-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
           </div>

           <!-- <div>
            <label for="start" class="block text-sm font-medium text-gray-700"
             >Start</label
            >
            <input
             v-model="form.start"
             id="start"
             type="date"
             class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
           </div>

           <div>
            <label for="end" class="block text-sm font-medium text-gray-700"
             >End</label
            >
            <input
             v-model="form.end"
             id="end"
             type="date"
             class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
           </div> -->
           <!-- 
           <div>
            <label for="dns" class="block text-sm font-medium text-gray-700"
             >DNS</label
            >
            <input
             v-model="form.dns"
             id="dns"
             type="text"
             class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
           </div>

           <div>
            <label for="repeat" class="block text-sm font-medium text-gray-700"
             >Repeat</label
            >
            <input
             v-model="form.repeat"
             id="repeat"
             type="number"
             class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
           </div> -->
           <!-- 
           <div>
            <button
             type="submit"
             class="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
             Submit
            </button>
           </div> -->
          </div>
         </div>
         <div class="my-2 text-sm text-gray-500">
          <div v-if="isSchedule">
           <SwitchGroup as="div" class="flex items-center mb-2">
            <Switch
             v-model="instant"
             :class="[
              instant ? 'body__header--button' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 encom_ring_color',
             ]">
             <span
              aria-hidden="true"
              :class="[
               instant ? 'translate-x-5' : 'translate-x-0',
               'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
             <span class="text-sm font-medium">
              {{ $t("Envoyez le alerte maintenant.") }}</span
             >
            </SwitchLabel>
           </SwitchGroup>
          </div>
          <p class="text-sm text-gray-500 pt-2">
           {{
            $t(
             "L'alerte vous sera envoyée par défaut. Si vous le souhaitez, vous pouvez ajouter d'autres destinataires."
            )
           }}
          </p>
         </div>
         <form
          class="mt-0 sm:flex sm:max-w-md lg:mt-0"
          @submit.prevent="pushToEmails(email)">
          <div>
           <label for="email-address" class="sr-only">Email address</label>
           <div class="relative">
            <input
             v-model="email"
             type="email"
             name="email-address"
             id="email-address"
             autocomplete="email"
             required
             :class="[
              this.invalidEmail
               ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 '
               : 'border-gray-300 placeholder-gray-400 ',
              'shadow-sm block w-full sm:text-sm rounded-md',
             ]"
             placeholder="you@example.com" />
            <div
             v-if="this.invalidEmail"
             class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
             <ExclamationCircleIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true" />
            </div>
           </div>
           <p
            v-if="this.invalidEmail"
            class="text-sm text-red-600"
            id="email-error">
            {{
             !this.emailRegexError
              ? $t("pbxReport.emailRegexError")
              : $t("pbxReport.emailAlreadyExistError")
            }}
           </p>
          </div>
          <div class="mt-0 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
           <button
            type="submit"
            class="w-full border hover:bg-gray-100 border-gray-600 rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium">
            {{ $t("Ajouter un destinataire") }}
           </button>
          </div>
         </form>
        </div>
        <div
         @click="$emit('alertList')"
         class="text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full encom_primary encom_primary_hover sm:mx-0 sm:h-10 sm:w-10 cursor-pointer">
         <font-awesome-icon icon="trash" class="h-5 w-5" aria-hidden="true" />
        </div>
       </div>
       <div class="py-1" v-show="emails.length > 0">
        <div v-for="email in emails" :key="email" class="inline-flex mb-1">
         <div class="mx-1">
          <span
           class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-600">
           {{ email }}
           <button
            @click="removeEmailFromList(email)"
            type="button"
            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-700 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
            <span class="sr-only">Remove option</span>
            <svg
             class="h-2 w-2"
             stroke="currentColor"
             fill="none"
             viewBox="0 0 8 8">
             <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7" />
            </svg>
           </button>
          </span>
         </div>
        </div>
       </div>
       <div class="pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
         type="submit"
         class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 encom_primary encom_primary_hover text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
         {{ $t("Valider") }}
        </button>
        <button
         type="button"
         class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
         @click="_cancel"
         ref="cancelButtonRef">
         {{ $t("ticket.cancel") }}
        </button>
       </div>
      </form>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import PopupModal from "../PopupModal.vue";
import axios from "axios";
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const timezone = localStorage.getItem("hostTimeZone");

const statsTypes = [
 //  { id: 0, name: "nombre total d'appels", type: "totalNumber" },
 //  { id: 1, name: "Durée totale des appels", type: "totalDuration" },
 {
  id: 6,
  name: "les appels non répondus",
  type: "answeredIncomming",
  value_type: "%",
 },
 {
  id: 4,
  name: "les durées d'attente",
  type: "totalDurationIncomming",
  value_type: "seconds",
 },
 {
  id: 2,
  name: "tous les appels entrants",
  type: "totalNumberIncomming",
 },
 { id: 3, name: "tous les appels sortants", type: "totalNumberOutgoing" },
 //  {
 //   id: 5,
 //   name: "Durée totale des appels sortants",
 //   type: "totalDurationOutgoing",
 //  },
 //  { id: 7, name: "appels entrants répondus", type: "answeredIncomming" },
 //  { id: 8, name: "appels sortants répondus", type: "answeredOutgoing" },
];

const statsMeasurementTypes = [
 { id: 0, name: "%", type: "percentage" },
 { id: 1, name: "nombre", type: "number" },
 { id: 1, name: "seconds", type: "seconds" },
];
const statsMeasurementLimits = [
 { id: 0, name: "supérieur(e)s à", type: "bigger" },
 { id: 1, name: "inférieur(e)s à", type: "lower" },
 { id: 2, name: "supérieur(e)s ou égal(e) à", type: "equalBigger" },
 { id: 3, name: "inférieur(e)s ou égal(e) à", type: "lowerEqual" },
 { id: 4, name: "égal(e)s à", type: "equal" },
];

export default {
 props: [
  "elementOption",
  "parseDateRange",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
  "startDate",
  "endDate",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   startDateCalendar: new Date(),
   statsMeasurementTypes,
   statsMeasurementLimits,
   statsTypes,
   form: {
    host_name: null,
    name: "",
    stats_type: null,
    stats_measurement_type: null,
    stats_measurement_limit: null,
    stats_measurement_value: null,
    start: null,
    start_at: null,
    end: null,
    dns: "*",
    repeat: true,
    repeat_pattern: null,
    email: "",
    email_sender: null,
   },
   cron: "",
   dataset: "",
   emails: [],
   email: "",
   invalidEmail: false,
   emailRegexError: false,
   emailAlreadyExistError: false,
   message: undefined, // Main text content
   nickname: "",
   title: undefined,
   isSchedule: false,
   hour: "",
   timezone,
   subMessage: undefined, // text content before input
   instant: false,
  };
 },
 components: {
  ExclamationCircleIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 methods: {
  cleanForm() {
   this.nickname = "";
   this.emails = [];
  },
  show(opts = {}) {
   this.title = opts.title;
   this.message = opts.message;
   this.isSchedule = opts.isSchedule;
   this.okButton = opts.okButton;
   if (opts.cancelButton) {
    this.cancelButton = opts.cancelButton;
   }
   if (opts.action) {
    this.action = opts.action;
   }
   // Once we set our config, we tell the popup modal to open
   this.$refs.popup.open();
   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  postAlert() {
   this.form.dns = "*";
   this.form.host_name = this.hostName;
   this.emails.indexOf(this.user.email) < 0
    ? this.emails.push(this.user.email)
    : "";
   this.form.email_sender = this.user.email ? this.user.email : null;
   this.form.email = this.emails.toString();

   switch (this.form.stats_type) {
    case "answeredIncomming":
     this.form.stats_measurement_type = "percentage";
     break;

    case "totalDurationIncomming":
     this.form.stats_measurement_type = "seconds";
     break;

    default:
     this.form.stats_measurement_type = "number";
     break;
   }

   const options = {
    method: "POST",
    url: `${localStorage.getItem("apiUrl")}/api/v1.2/${this.hostName}/alerts`,
    data: this.form,
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
    },
   };

   axios
    .request(options)
    .then(res => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("successMsg"));
     this.cleanForm();
    })
    .catch(err => {
     this.errorHandling(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.response);
    });
  },
  _confirm() {
   try {
    this.postAlert();
    this.$refs.popup.close();
    this.resolvePromise(true);
   } catch (error) {
    console.error(error);
   }
  },

  _cancel() {
   this.$refs.popup.close();
   this.resolvePromise(false);
   // Or you can throw an error
   // this.rejectPromise(new Error('User cancelled the dialogue'))
  },
  pushToEmails(email) {
   this.invalidEmail = false;
   this.emailRegexError = false;
   this.emailAlreadyExistError = false;

   if (this.validateEmail(email) && !this.emails.includes(email)) {
    this.emails.push(email);
    this.email = "";
   } else {
    this.invalidEmail = true;
    this.validateEmail(email)
     ? (this.emailRegexError = true)
     : (this.emailAlreadyExistError = true);
   }
  },
  removeEmailFromList(email) {
   let index = this.emails.indexOf(email);
   this.emails.splice(index, 1);
  },
  validateEmail(email) {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
  },
 },
 computed: {
  ...mapGetters([
   "pbxElements",
   "pbxTimeSelected",
   "pbxTimeSelectedEnd",
   "hostName",
   "user"
  ]),
 },
 watch: {},
};
</script>

<style></style>
